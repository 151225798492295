@import "./normalize.scss";
@import "./variables.scss";

body {
  color: $grey-dark;
  font-family: sans-serif;
  background-color: #eee;
}

.container {
  max-width: 1050px;
  margin: 0 auto;
  display: block;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input,
textarea {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.button-link {
  display: block;
  font-size: 1rem;
  letter-spacing: 2px;
  cursor: pointer;
  padding: 15px 50px;
  border: 2px solid $grey-dark;
  border-radius: 30px;
  font-weight: 600;
  background: transparent;
  color: $grey-dark;
  transition: border 0.3s, color 0.3s;

  &:hover {
    border: 2px solid $champagne-pink;
    color: $champagne-pink;
  }
}

.button {
  display: block;
  font-size: 1rem;
  letter-spacing: 2px;
  cursor: pointer;
  padding: 6px;
  border: 1px solid $grey-dark;
  border-radius: 4px;
  background: transparent;
  color: $grey-dark;
  transition: all 0.3s;

  &.danger {
    color: $red;
    border: 1px solid $red;
  }

  &:hover {
    color: $champagne-pink;
  }
}

.confirm-delete-toast {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rc-tooltip {
  opacity: 1 !important;
}

// @media only screen and (min-width: 1150px) {

// }
