@import "styles/variables.scss";

.loader {
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 100%;
  background: $grey-dark;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
